<template>
    <div style="height: 100%; text-align: center;">
        自动登录中，请稍后...
    </div>
</template>

<script>
export default {
    name: "SplashView"
}
</script>

<style scoped>

</style>